import React from "react";
import styles from "./Button.module.css";


const Button = ({onClick, children, colourScheme, ...passThroughProps}) => {

	let classStyle = styles.button;
	if(colourScheme && colourScheme === "oceanDark") {
		classStyle += ` ${styles.oceanDark}`
	} else if (colourScheme && colourScheme === "earthDark") {
		classStyle += ` ${styles.earthDark}`
	}
	return (
		<button className={classStyle} onClick={onClick} {...passThroughProps}>
			{children}
		</button>
	)
};
export default Button;