import React, { useState } from "react";

import Button from "../common/Button/Button";
import Triage from "../Triage/Triage";

import PainFlow from "../TriageFlows/painFlow.json";
import UlcerationFlow from "../TriageFlows/ulcerationFlow.json";
import BleedingFlow from "../TriageFlows/bleedingFlow.json";
import TraumaFlow from "../TriageFlows/traumaFlow.json";

import KirokuLaptopImg from "../images/kiroku-laptop.png";
import BackArrowIcon from "../icons/back-arrow.svg";
import KirokuLogo from "../icons/kiroku-logo.svg";
import { motion } from "framer-motion";
import styles from "./App.module.css";

const TriageFlow = {
  ...PainFlow,
  ...UlcerationFlow,
  ...BleedingFlow,
  ...TraumaFlow
};

const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

function App() {
  const [startTriage, setStartedTriage] = useState(false);
  const [previousList, setPreviousList] = useState([]);
  const [currentSection, setCurrentSection] = useState(null);

  return (
    <div className={styles.mainContainer}>
      <header className={styles.header}>
        {currentSection ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              // if we have a previous section, set it to the previous one
              if (previousList.length > 0) {
                const copyList = [...previousList];
                setCurrentSection(copyList.pop());
                setPreviousList([...copyList]);
              } else {
                // if not reset everything
                setCurrentSection(null);
              }
            }}
          >
            <img src={BackArrowIcon} alt="back arrow" />
          </span>
        ) : startTriage ? (
          <a href="/">
            <span>
              <img src={BackArrowIcon} alt="back arrow" />
            </span>
          </a>
        ) : (
          <a href="https://trykiroku.com" target="_blank">
            <span className={styles.visit}>Visit our website</span>
          </a>
        )}
        {startTriage ? (
          <a href="/">
            <span className={`subtitle`}>Home</span>
          </a>
        ) : (
          <a href="/">
            <span className={`subtitle ${styles.title}`}>
              Covid 19 Triage Guidance
            </span>
          </a>
        )}
        <a href="https://trykiroku.com" target="_blank">
          <span className={styles.logo}>
            <span>Kiroku</span>
            <img src={KirokuLogo} alt="Kiroku logo" />
          </span>
        </a>
      </header>

      <div className={styles.appContainer}>
        {startTriage ? (
          currentSection ? (
            <Triage
              currentSection={TriageFlow[currentSection]}
              updateCurrentSection={number => {
                // update current section and add previous current to the previous list
                setPreviousList([...previousList, currentSection]);
                setCurrentSection(number);
              }}
            />
          ) : (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={container}
              className={styles.queryContainer}
            >
              <p>Which issue do you need to query?</p>
              <Button onClick={() => setCurrentSection("pain-1")}>
                Patient has pain
              </Button>
              <Button onClick={() => setCurrentSection("ulceration-1")}>
                Patient has an area of ulceration/inflammation/red area or
                abnormal appearance affecting mucosa
              </Button>
              <Button onClick={() => setCurrentSection("bleeding-1")}>
                Patient has bleeding following trauma or extraction
              </Button>
              <Button onClick={() => setCurrentSection("trauma-1")}>
                Patient has had trauma to their tooth
              </Button>
            </motion.div>
          )
        ) : (
          <div className={styles.startContent}>
            <div>
              <h2 className="headline">Welcome to Kiroku's COVID-19 triage</h2>
              <Button
                colourScheme="oceanDark"
                onClick={() => setStartedTriage(true)}
              >
                Begin triage
              </Button>
            </div>
            <div className={styles.aboutSection}>
              <h4 className="subtitle">What's this about?</h4>
              <p>
                This resource has been created to assist clinicians and
                associated health care professionals in triaging emergency
                patients during the COVID 19 pandemic.
              </p>
              <p>
                The information provided has been taken from the{" "}
                <a
                  href="http://www.sdcep.org.uk/wp-content/uploads/2020/03/SDCEP-MADP-COVID-19-guide-300320.pdf"
                  target="_blank"
                >
                  SDCEP guidance
                </a>{" "}
                and{" "}
                <a
                  href="https://www.baos.org.uk/wp-content/uploads/2020/04/Patients-Flow-Diagram-04-2020.pdf"
                  target="_blank"
                >
                  BAOS guidance
                </a>
                .
              </p>
              <p>
                We recommend that users familiarise themselves with these source
                documents prior to using this resource and also employ clinical
                judgment as the situation dictates.
              </p>
              <p>
                The purpose of this resource is purely a tool to help the user
                gain an easier understanding of the already existing guidance.
              </p>
            </div>
            <div className={styles.card}>
              <h4 className="headline">
                Have you tried our app for clinical notes?
              </h4>
              <img src={KirokuLaptopImg} alt="Laptop using kiroku" />
              <p>
                Kiroku is a note-taking app that uses AI to help you complete
                notes in half the time.
              </p>
              <a href="https://trykiroku.com" target="_blank">
                <Button colourScheme="earthDark">Learn more</Button>
              </a>
            </div>
          </div>
        )}
      </div>

      <footer className={styles.footer}>
        <div>
          <span>Kiroku website</span>
          <span>Privacy</span>
          <span>Contact</span>
        </div>
        <p>© Kiroku 2020. All rights reserved</p>
      </footer>
    </div>
  );
}

export default App;
